import React from 'react'
import cn from 'classnames'

import { ThemeEnum } from '@hooks/contexts/ThemeContext/interface'
import { HalfMoonIcon } from '@components/icons/HalfMoonIcon'
import { LightIcon } from '@components/icons/LightIcon'
import { ThemeSwitchProps } from './interface'

export function ThemeSwitch({
  theme,
  handleThemeChange,
  isAuthen,
}: ThemeSwitchProps) {
  const isLightOrSepiaTheme = [ThemeEnum.LIGHT, ThemeEnum.SEPIA].includes(theme)
  const isNightOrDarkTheme = [ThemeEnum.NIGHT, ThemeEnum.DARK].includes(theme)

  const onSwitchTheme = () => {
    if (isLightOrSepiaTheme) {
      handleThemeChange(ThemeEnum.NIGHT)
    } else {
      handleThemeChange(ThemeEnum.LIGHT)
    }
  }

  return (
    <button
      onClick={onSwitchTheme}
      className={cn(
        'flex items-center justify-between rounded-xl transition duration-300',
        {
          'mobile:pr-0 p-2': isAuthen,
          'mobile:pr-2 p-2': !isAuthen,
          'desktop-tablet:hover:bg-dark-9': isLightOrSepiaTheme,
          'desktop-tablet:hover:bg-primary': isNightOrDarkTheme,
        }
      )}
    >
      <div className='flex items-center'>
        {isLightOrSepiaTheme ? (
          <HalfMoonIcon
            className='mx-auto text-white-2'
            width='20'
            height='20'
          />
        ) : (
          <LightIcon className='mx-auto text-dark-10' width='20' height='20' />
        )}
      </div>
    </button>
  )
}
