import React, { useState } from 'react'
import Router, { useRouter } from 'next/router'
import Image from 'next/image'
import cn from 'classnames'
import dynamic from 'next/dynamic'
import Link from 'next/link'

import { Button } from '@components/Buttons'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useTheme } from '@hooks/contexts/ThemeContext/ThemeContext'
import { ThemeEnum } from '@hooks/contexts/ThemeContext/interface'
import { MenuTypeEnum } from '@interfaces/MenuTypeEnum'
import { useCategories } from '@hooks/useCategories'
import { BookmarkIcon } from '@components/icons/BookmarkIcon'
import { MoonIcon } from '@components/icons/MoonIcon'
import { BanModal } from '@features/authentication/components/BanModal'
import { SearchMenu } from './SearchMenu'
import { HeaderProps } from '../interfaces/Header'
import { Menu } from './Menu'
import { SubMenu } from './SubMenu'
import { ThemeSwitch } from './ThemeSwitch'

const NotificationPopup = dynamic<unknown>(() =>
  import('./NotificationPopup').then(v => v.NotificationPopup)
)
const Chat = dynamic<unknown>(() => import('./Chat').then(v => v.Chat))
const ProfileMenu = dynamic<unknown>(() =>
  import('./ProfileMenu').then(v => v.ProfileMenu)
)

export function Header({
  menus,
  activeMenu,
  isAuthen,
  handleActiveMenu,
}: HeaderProps) {
  const { theme } = useTheme()
  const loginModal = useModal({ modal: 'login' })
  const router = useRouter()
  const isNightOrDarkTheme = [ThemeEnum.NIGHT, ThemeEnum.DARK].includes(theme)
  const { categories, novelCategories, mangaCategories } = useCategories()
  const [activeSearch, setActiveSearch] = useState(false)

  function handleActiveSearchMenu() {
    setTimeout(() => {
      const inputElement = document.getElementById('search-input-id')
      inputElement?.focus()
    }, 200)
    setActiveSearch(prev => !prev)
  }

  function closeSearchMenu() {
    setActiveSearch(false)
  }

  function handleClickMenu(value: MenuTypeEnum) {
    if ([MenuTypeEnum.NOVEL, MenuTypeEnum.MANGA].includes(value)) {
      router.push(`/all-category?bookType=${value}`)
    }
  }

  return (
    <div
      className={cn(
        'px-[15px] flex sticky top-0 bg-primary dark:bg-dark-9 z-30',
        {
          '!bg-dark-7':
            theme === ThemeEnum.DARK &&
            Router.pathname === '/story/[id]/chapter/[chapterId]',
        }
      )}
    >
      <Link href='/' legacyBehavior prefetch={false}>
        <a className={cn('flex items-center my-auto')}>
          <img
            className={cn('cursor-pointer', {
              '!opacity-50':
                theme === ThemeEnum.DARK &&
                Router.pathname === '/story/[id]/chapter/[chapterId]',
            })}
            src='/logo-light.webp'
            alt='Mareads'
            width={156}
            height={50}
          />
        </a>
      </Link>
      <Menu>
        {menus.map(menu => (
          <Menu.Item
            className={cn(
              'relative flex items-center h-[60px] font-medium dark:text-dark-10',
              {
                'text-white opacity-50 dark:text-dark-10':
                  activeMenu !== menu.menuType,
                'text-white':
                  activeMenu === menu.menuType && !isNightOrDarkTheme,
                'text-primary':
                  activeMenu === menu.menuType && isNightOrDarkTheme,
              }
            )}
            key={menu.menuType}
            title={menu.name}
            onMouseEnter={() => {
              handleActiveMenu(menu.menuType)
              closeSearchMenu()
            }}
            onMouseLeave={() => handleActiveMenu('')}
          >
            {[MenuTypeEnum.NOVEL, MenuTypeEnum.MANGA].includes(
              menu.menuType
            ) ? (
              <Link
                href={`/all-category?bookType=${menu.menuType}`}
                legacyBehavior
                prefetch={false}
              >
                <a>
                  <Menu.Name
                    className='font-mitr'
                    icon={menu.icon}
                    name={menu.name}
                  />
                </a>
              </Link>
            ) : menu.menuType === MenuTypeEnum.EBOOK ? (
              <Link href='/all-category-ebook' legacyBehavior prefetch={false}>
                <a>
                  <Menu.Name
                    className='font-mitr'
                    icon={menu.icon}
                    name={menu.name}
                  />
                </a>
              </Link>
            ) : (
              <Menu.Name
                className='font-mitr'
                icon={menu.icon}
                name={menu.name}
              />
            )}
            {menu.subMenu && (
              <SubMenu
                theme={theme}
                isActive={activeMenu === menu.menuType}
                menu={menu.menuType as MenuTypeEnum}
                pathname={menu.pathname}
                categories={
                  menu.menuType === MenuTypeEnum.MANGA
                    ? mangaCategories
                    : menu.menuType === MenuTypeEnum.NOVEL
                    ? novelCategories
                    : categories
                }
              />
            )}
          </Menu.Item>
        ))}
      </Menu>
      {isAuthen ? (
        <>
          <div className='flex items-center ml-auto space-x-[15px] h-[60px]'>
            <ThemeSwitch isAuthen />
            <SearchMenu
              handleActiveSearchMenu={handleActiveSearchMenu}
              activeSearch={activeSearch}
              closeSearchMenu={closeSearchMenu}
            />

            <button
              className='text-white dark:opacity-50'
              type='button'
              onClick={() => router.push('/my-reading/bookmark')}
              data-test='bookmark-menu'
            >
              <BookmarkIcon />
            </button>

            <div className='w-[1px] h-[24px] bg-white dark:opacity-50' />
            <Chat />
            <NotificationPopup />
            <ProfileMenu />
          </div>
        </>
      ) : (
        <div className='flex ml-auto items-center'>
          <ThemeSwitch isAuthen={false} />
          <SearchMenu
            handleActiveSearchMenu={handleActiveSearchMenu}
            activeSearch={activeSearch}
            closeSearchMenu={closeSearchMenu}
          />
          <div className='border-r border-white h-[20px] ml-[15px]' />

          <Button
            data-test='login'
            onClick={() => loginModal.show()}
            className='font-medium font-mitr text-[14px] text-gray-4  my-[10px] px-[20px]'
            type='button'
          >
            <MoonIcon className='mr-[10px]' width='24' height='24' />
            <span className='mobile:max-w-[80px]'>
              เข้าสู่ระบบ / สมัครสมาชิก
            </span>
          </Button>
        </div>
      )}
    </div>
  )
}
