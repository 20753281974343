import React from 'react'

import { ThemeEnum } from '@hooks/contexts/ThemeContext/interface'
import { useTheme } from '@hooks/contexts/ThemeContext/ThemeContext'
import { ThemeSwitchProps } from './interface'

export function withThemeSwitch(Component: React.FC<ThemeSwitchProps>) {
  function WithThemeSwitch({ isAuthen }: { isAuthen: boolean }) {
    const { theme, updateTheme } = useTheme()

    function handleThemeChange(value: ThemeEnum) {
      updateTheme(value)
    }

    const componentProps = {
      theme,
      handleThemeChange,
      isAuthen,
    }

    return <Component {...componentProps} />
  }

  return WithThemeSwitch
}
