import React from 'react'
import Head from 'next/head'
import Script from 'next/script'
import { BECOOKIES_ID } from '@configs/config'
import { AppLayoutProps } from '../interfaces/AppLayout'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { MenuBar } from '../components/MenuBar'

export function AppLayout({
  children,
  menus,
  activeMenu,
  isAuthen,
  seoDescription,
  appendKeyword = '',
  handleActiveMenu,
}: AppLayoutProps) {
  return (
    <div className='bg-white min-h-screen flex flex-col dark:bg-dark-7'>
      <Head>
        <meta
          name='description'
          content={
            seoDescription ||
            `อ่านฟรี คลังนิยาย คลังการ์ตูน นิยายออนไลน์ นิยายแปล เว็บตูน รักโรแมนติก boy's love yuri แฟนตาซี และอีกมากมายได้ที่ MaReads จักรวาลของนักอ่านนักเขียน หรือซื้อรูปเล่มจากร้านนายอินทร์ออนไลน์หรือหนังสือในเครืออมรินทร์ได้เลย`
          }
        />
        <meta
          name='keywords'
          content={`mareads,maread,มารี้ด,มารีด,นิยายออนไลน์,อ่านนิยาย,นิยาย,อ่านนิยายออนไลน์,อ่านการ์ตูน,อ่านอีบุ๊ก,อีบุ๊ก,E-book,ebook,นักเขียน,นักอ่าน,งานเขียน,บทความ,เรื่องสั้น,ฟิค,แต่งฟิค,แต่งนิยาย,นิยายแปล,นิยายจีน,นิยายเกาหลี${appendKeyword}`}
        />
        {/* <script
          id='becookies.tech-scripts'
          src='https://www.becookies.tech/script.js'
          data-id={BECOOKIES_ID}
          charSet='utf-8'
        /> */}
        <Script
          id='becookies.tech-scripts'
          src='https://www.becookies.tech/script.js'
          strategy='lazyOnload'
          data-id={BECOOKIES_ID}
          charSet='UTF-8'
        />
      </Head>
      <Header
        menus={menus}
        activeMenu={activeMenu}
        isAuthen={isAuthen}
        handleActiveMenu={handleActiveMenu}
      />
      {children}
      <Footer />
      <MenuBar />
    </div>
  )
}
