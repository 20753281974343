import React from 'react'
import { Formik, Form } from 'formik'
import cn from 'classnames'

import { CrossIcon } from '@components/icons/CrossIcon'
import { SearchIcon } from '@components/icons/SearchIcon'
import { useTheme } from '@hooks/contexts/ThemeContext/ThemeContext'
import { ThemeEnum } from '@hooks/contexts/ThemeContext/interface'
import { SearchField } from '@features/search/components/SearchField'
import { SearchMenuProps } from './interface'

export function SearchMenu({
  initialValues,
  activeSearch,
  disabledMenu,
  onSearch,
  handleActiveSearchMenu,
}: SearchMenuProps) {
  const { theme } = useTheme()
  return (
    <div className='flex items-center'>
      <button
        onClick={handleActiveSearchMenu}
        className={cn('text-white dark:opacity-50', {
          'cursor-no-drop opacity-40': disabledMenu,
        })}
        type='button'
        disabled={disabledMenu}
        data-test='search-menu'
        aria-label='Toggle search menu'
      >
        <SearchIcon />
      </button>
      <div
        className={cn(
          'flex flex-row items-center justify-between shadow px-[15px] mobile:px-[20px] top-[60px] left-0 absolute h-[80px] mobile:h-[60px] w-full z-10 dark:bg-dark-9',
          {
            'bg-primary': [ThemeEnum.LIGHT, ThemeEnum.SEPIA].includes(theme),
            hidden: !activeSearch,
          }
        )}
      >
        <Formik initialValues={initialValues} onSubmit={onSearch}>
          <Form className='w-full mr-[15px]'>
            <SearchField
              name='text'
              placeholder='ค้นหาด้วยชื่อเรื่อง, นักเขียน, แท็ก, ตัวละคร หรืออื่นๆ'
              inputClassName='font-light text-secondary w-full rounded-[20px]'
              className={cn('w-full', {
                'sepia-mode': theme === ThemeEnum.SEPIA,
                'night-mode': theme === ThemeEnum.NIGHT,
                'dark-mode': theme === ThemeEnum.DARK,
              })}
              isShowBorder={false}
            />
          </Form>
        </Formik>
        <button
          type='button'
          onClick={handleActiveSearchMenu}
          aria-label='Close search menu'
        >
          <CrossIcon
            className={cn('cursor-pointer', {
              'text-white': [ThemeEnum.LIGHT, ThemeEnum.SEPIA].includes(theme),
              'text-dark-10': [ThemeEnum.NIGHT, ThemeEnum.DARK].includes(theme),
            })}
          />
        </button>
      </div>
    </div>
  )
}
